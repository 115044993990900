<template>
  <div class="list-manage contract-details">
    <section class="list-manage-table">
      <div class="table-title"><span class="title">合同管理</span></div>
      <div class="contract-details-main"  v-loading="loading">
        <el-image :src="detail.contract_img" style="width:820px">
          <div slot="error" class="image-slot">
            <img src="~@/assets/images/temp/temp_05.png" class="device-pic" alt="">
          </div>
        </el-image>
      </div>
    </section>
  </div>
</template>

<script>
import {con_find} from '@/api/contract'
  import kits from "@/kits"
  export default {
    data() {
      return {
        id: null,
        detail:{},
        loading:true
      };
    },
    created(){
      this.getdetails();
    },
    methods: {
      getid(){
        this.id = this.$route.query.id
      },
      getdetails(){
        this.getid()
        con_find({"inputs": {"contract_id":this.id}})
        .then(res => {
          this.detail = res.data
          if (this.detail.contract_img) {
            this.detail.contract_img = kits.img.url(this.detail.contract_img)
          }
          this.loading = false
        })
        .catch((err) => {
        // 出错时要做的事情
        console.log(err);
      });
      }
    }
  }
</script>

<style scoped>
</style>
